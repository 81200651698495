.btn {
  flex: 1;
  margin: 1px !important;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

@media only screen and (min-width: 600px) {
  .btn {
    max-width: 400px;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}
