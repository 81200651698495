@media only screen and (min-width: 600px) {
  .btn {
    max-width: 400px;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .flex-row {
    display: flex;
    align-items: center;
  }
  .btn-group-vertical {
    display: inline-block;
  }
  .col {
    position: relative;
    width: 400px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
